var generic = generic || {};

(function ($) {
  'use strict';

  Drupal.behaviors.basicV1 = {
    attach: function (context, settings) {
      var signed_in = parseInt(site.userInfoCookie.getValue('signed_in'));
      var loyalty_level = parseInt(site.userInfoCookie.getValue('loyalty_level'));
      var is_loyalty_member = parseInt(site.userInfoCookie.getValue('is_loyalty_member'));
      var $faq_template = $('.js-loyalty-cancellation-faq', context);

      $faq_template.find('#tier-3-content').parents('.collapsible-block').hide();
      if (is_loyalty_member && loyalty_level == 3) {
        $faq_template.find('#tier-3-content').parents('.collapsible-block').show();
      }
    }
  };
})(jQuery);
